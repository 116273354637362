import React from 'react';

import styled from '@emotion/styled';
import { spacing, mediaQuery, breakpoint } from 'styles';

import {
  Accordion,
  Icon,
  Heading,
  Body,
} from 'components';

export const Section = styled.article`
  margin-top: ${spacing.chapter * 3}px;
  margin-bottom: ${spacing.chapter}px;
`;

export const HeadingDescription = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${spacing.chapter}px;

  ${Body} {
    flex-grow: 1;
  }

  ${breakpoint(mediaQuery.tablet, 'max')} {
    br {
      display: none;
    }
  }

  .Icon {
    font-size: 18px;
    margin-right: 6px;
  }
`;

const FAQ = () => (
  <Section>
    <Heading>
      도움이 필요하신가요?  🤔
    </Heading>
    <HeadingDescription>
      <Body>
        많은 분이 궁금해하실 것 같은 내용을 아래에 적어두었어요.
        <br />
        혹시 다른 궁금한 점이 있으시다면, 오른쪽의&nbsp;
        <Icon type='chat_bubble' />
        메세지 버튼을 눌러 문의해주세요.
      </Body>
    </HeadingDescription>
    <Accordion
      title='교육비가 필요한가요? 얼마인가요?'
      data-gtm='click.faq'
      data-faq-id='payment'
    >
      <Body>
        설리번 프로젝트의 모든 교육은 <u>초, 중, 고등학생 나이대에 해당하시는 분들께 무료</u>로 진행됩니다.
        <br />
        다만, 부품을 사용해야 하는 아두이노 커리큘럼의 경우, 부품비가 필요할 수 있으며,
        <br />
        또한, 대학생 나이대에 해당하시는 분들은 보증금을 받게 됩니다. 자세한 내용은 지원 시 안내사항을 확인해주세요.
      </Body>
    </Accordion>
    <Accordion
      title='선발에 인원 제한이 있나요? 지원하면 다 뽑히나요?'
      data-gtm='click.faq'
      data-faq-id='student-limit'
    >
      <Body>
        커리큘럼에 따라 다소 상이하나, 한 커리큘럼 당 평균 10명 정도의 학생을 받고 있습니다.
        <br />
        지원하신 분들 모두와 함께하고 싶으나, 한정된 자원과 시간으로 인하여 그러지 못하는 점 양해 부탁드립니다.
      </Body>
    </Accordion>
    <Accordion
      title='노트북이 꼭 필요한가요? 노트북 대여도 가능한가요?'
      data-gtm='click.faq'
      data-faq-id='notebook-required'
    >
      <Body>
        네, 커리큘럼 소개자료에 관련 공지가 없다면 <u>노트북이 꼭 필요</u>합니다.
        <br />
        노트북이 없지만 수업을 듣고자 하시는 분들을 위하여 강의가 진행되는 동안 노트북을 대여해드리고 있습니다.
        <br />
        자세한 문의는&nbsp;
        <u>
          <a href='mailto:contact@sullivanproject.in'>
            contact@sullivanproject.in
          </a>
        </u>
        으로 연락 부탁드립니다.
      </Body>
    </Accordion>
    <Accordion
      title='코딩은 처음인데 수업을 들을 수 있을까요?'
      data-gtm='click.faq'
      data-faq-id='first-coding'
    >
      <Body>
        설리번 프로젝트에서는 다양한 난이도의 수업이 준비되어 있습니다.
        <br />
        각각의 커리큘럼 소개들을 읽어보시고 본인이 가장 하고 싶은 것, 만들고 싶은 것, 수업의 난이도를 확인하신 후에 지원하시면 좋을 것 같습니다.
      </Body>
    </Accordion>
    <Accordion
      title='지원서를 부모가 작성해도 괜찮은가요?'
      data-gtm='click.faq'
      data-faq-id='parent'
    >
      <Body>
        타이핑을 도와주시더라도 <u>지원서 자체는 아이의 의견으로 작성</u>할 수 있도록 도와주세요. 저희는 본인의 의지를 우선하여 평가합니다.
        <br />
        만약 본인이 작성하지 않았다고 판단될 경우, <u>선발에 불이익</u>이 있을 수 있습니다.
      </Body>
    </Accordion>
    <Accordion
      title='교육은 언제부터 언제까지 진행되나요?'
      data-gtm='click.faq'
      data-faq-id='startdate'
    >
      <Body>
        설리번 프로젝트는 <u>2020년 1월부터 2월까지</u> 약 1개월 간 진행되며, 모든 교육은 2020년 1월 6일 이후 진행될 예정입니다.
        <br />
        각 커리큘럼 별 차시 및 일정이 상이하므로, 자세한 내용은 해당 커리큘럼의 상세 페이지에서 확인해주시기 바랍니다.
      </Body>
    </Accordion>
  </Section>
);

export default FAQ;