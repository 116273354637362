import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  colors,
  spacing,
  breakpoint,
  mediaQuery,
} from 'styles';

import { Map, Heading, Body } from 'components';

type Props = {
  placeName: string;
  address?: string;
};

const MapContainer = styled.div`
  margin-top: ${spacing.chapter * 2}px;
  margin-bottom: ${spacing.chapter}px;
`;

const MapInfoContainer = styled.div`
  margin-bottom: ${spacing.row}px;

  ${breakpoint(mediaQuery.tablet)} {
    display: flex;
    align-items: center;

    .column {
      flex-grow: 1;
      margin: 0;

      &:last-of-type {
        text-align: right;
      }
    }
  }
`;

const mapStyle = css`
  height: 250px;

  ${breakpoint(mediaQuery.tablet)} {
    height: 320px;
  }

  ${breakpoint(mediaQuery.desktop)} {
    height: 400px;
  }
`;

const CurriculumMap = ({
  placeName,
  address,
}: Props) => (
  <MapContainer>
    <Heading
      css={css`
        margin-bottom: ${spacing.row}px;
      `}
    >
      교육 장소 🗺️
    </Heading>
    {!!address && (
      <>
        <MapInfoContainer>
          <div className='column'>
            <Body>
              {placeName}
            </Body>
          </div>
          <div className='column'>
            <Body
              css={css`
                font-weight: 300;
                color: ${colors.gray[50]};
              `}
            >
              {address}
            </Body>
          </div>
        </MapInfoContainer>
        <Map
          css={mapStyle}
          placeName={placeName}
          address={address}
        />
      </>
    )}
  </MapContainer>
);

CurriculumMap.defaultProps = {
  address: '',
};

export default CurriculumMap;