import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import styled from '@emotion/styled';
import {
  typography,
  colors,
  spacing,
  breakpoint,
  mediaQuery,
} from 'styles';

import debounce from 'lodash.debounce';
import { copy } from 'utils';

import {
  Button,
  Icon,
  Body,
  Description,
} from 'components';

import { HOST } from '../../../constants';

type Props = {
  slug: string;
  calendar: string;
  when: string;
  placeName: string;
  address: string;
};

const LIMIT = 450;
const SCROLL_PADDING = 120;

const FakeWrapper = styled.div`
  ${breakpoint(mediaQuery.desktop, 'max')} {
    width: 100%;
    margin-bottom: ${spacing.chapter * 2}px;
  }

  ${breakpoint(mediaQuery.desktop)} {
    flex-shrink: 0;
    width: 360px;
  }
`;

const SummaryContainer = styled.div`
  z-index: 5;

  ${breakpoint(mediaQuery.desktop)} {
    position: absolute;
    width: 360px;
    height: 100%;
  }
`;

const ScrollArea = styled.div`
  transition: .3s margin ease;
  will-change: margin;

  ${breakpoint(mediaQuery.desktop, 'max')} {
    margin: 0 !important;
  }
`;

const InfoContainer = styled.div`
  padding: 32px 24px;
  margin-bottom: ${spacing.row + spacing.leading}px;
  background: white; 
  border: solid 1px ${colors.gray[40]};
  border-radius: 4px;
`;

const RowContent = styled(Body)`
  flex-grow: 1;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: ${spacing.row}px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .Icon {
    margin-right: 24px;
    font-size: 20px;
    color: ${colors.gray[50]};
  }
`;

const ApplyButton = Button.withComponent('a');

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Button}, ${ApplyButton} {
    font-size: ${typography.description}px;
    margin-bottom: ${spacing.leading}px;
  }

  @media all and (min-width: ${mediaQuery.tablet}px) and (max-width: ${mediaQuery.desktop - 1}px) {
    flex-direction: row-reverse;

    ${Button}, ${ApplyButton} {
      width: 50%;

      &:first-of-type {
        margin-left: 6px;
      }

      &:last-of-type {
        margin-right: 6px;
      }
    }
  }

  ${breakpoint(mediaQuery.desktop)} {
    flex-direction: column;
  }
`;

const CurriculumSummary = ({
  slug,
  calendar,
  when,
  placeName,
  address,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLDivElement>(null);

  const [hasScrolled, setHasScrolled] = useState(false);
  const [style, setStyle] = useState<{} | undefined>(undefined);

  const curriculumID = useMemo(() => (
    slug.replace('/curriculum/', '')
      .replace(/\/$/g, '')
      .replace('/', '-')
  ), [slug]);

  const handleScrollDocument = useCallback(debounce(() => {
    const { scrollY } = window;

    if (scrollY >= LIMIT) {
      setHasScrolled(true);
    }

    if (window.innerWidth < mediaQuery.desktop) {
      return;
    }

    if (!containerRef || !containerRef.current) {
      return;
    }

    if (!summaryRef || !summaryRef.current) {
      return;
    }

    const { top, height } = containerRef.current.getBoundingClientRect();
    const containerHeight = summaryRef.current.clientHeight;
    const absTop = Math.abs(top);

    if (top > 0) {
      setStyle(undefined);
      return;
    }

    if ((absTop + containerHeight + SCROLL_PADDING) > height) {
      return;
    }

    setStyle({
      marginTop: absTop + SCROLL_PADDING,
    });
  }, 200), []);

  const handleClickApply = useCallback((e) => {
    if (!hasScrolled) {
      e.preventDefault();
      window.alert('내용을 모두 읽으셨나요?\n빠뜨린 내용이 있는지 한 번만 더 읽어보시고 지원해주세요!');
    }
  }, [hasScrolled]);

  const handleClickShare = useCallback(() => {
    const { origin, pathname } = window.location;
    copy(`${origin}${pathname}`);

    window.alert('주소가 복사되었습니다!\n공유하고자 하는 곳에 공유해주세요 :)');
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleScrollDocument);

    return () => {
      window.removeEventListener('scroll', handleScrollDocument);
    };
  }, []);

  return (
    <FakeWrapper>
      <SummaryContainer ref={containerRef}>
        <ScrollArea
          ref={summaryRef}
          style={style}
        >
          <InfoContainer>
            <Row>
              <Icon type='outlined_flag' />
              <RowContent>
                {calendar}
              </RowContent>
            </Row>
            <Row>
              <Icon type='access_time' />
              <RowContent>
                {when}
              </RowContent>
            </Row>
            <Row>
              <Icon type='map' />
              <RowContent
                as='div'
              >
                {placeName}
                <br />
                <Description>
                  {address}
                </Description>
              </RowContent>
            </Row>
          </InfoContainer>
          <ActionContainer>
            <ApplyButton
              href={`${HOST.DEEPLINK}${curriculumID}`}
              target='_blank'
              rel='noreferrer noopener'
              type='primary'
              onClick={handleClickApply}
              data-gtm={hasScrolled ? 'conversion.apply' : undefined}
            >
              지원하기
            </ApplyButton>
            <Button
              type='basic'
              onClick={handleClickShare}
              data-gtm='click.share'
            >
              공유하기
            </Button>
          </ActionContainer>
        </ScrollArea>
      </SummaryContainer>
    </FakeWrapper>
  );
};

export default CurriculumSummary;
