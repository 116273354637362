import React from 'react';
import { Link } from 'gatsby';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
  colors,
  spacing,
  breakpoint,
  mediaQuery,
} from 'styles';

import {
  Icon,
  Image,
  Description,
} from 'components';

type Props = {
  title: string;
  category: string;
  coverImage: string;
};

const TemplateHeader = styled.div`
  padding-top: 160px;
  margin-bottom: ${spacing.chapter}px;

  ${breakpoint(mediaQuery.mobile)} {
    padding-top: 200px;
  }
`;

const BreadcrumbItem = styled(Description.withComponent('li'))`
  margin: 0;
  font-weight: 500;
  color: ${colors.gray[50]};
`;

const Breadcrumb = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 0 ${spacing.row}px;

  .Icon {
    margin: 0 8px;
    color: ${colors.gray[40]};
    font-size: 18px;
  }
`;

const Title = styled.h1`
  font-family: inherit;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  color: ${colors.gray[90]};

  ${breakpoint(mediaQuery.tablet)} {
    font-size: 40px;
  }
`;

const ImageStyle = css`
  width: 100%;
  height: 250px;
  margin-bottom: ${spacing.section}px;

  ${breakpoint(mediaQuery.tablet)} {
    height: 400px;
  }

  ${breakpoint(mediaQuery.desktop)} {
    height: 550px;
    margin-bottom: ${spacing.chapter * 2}px;
  }
`;

const CurriculumTemplateHeader = ({
  category,
  title,
  coverImage,
}: Props) => (
  <>
    <TemplateHeader>
      <Breadcrumb>
        <BreadcrumbItem>
          <Link to='/curriculum'>
            커리큘럼
          </Link>
        </BreadcrumbItem>
        <Icon type='keyboard_arrow_right' />
        <BreadcrumbItem>
          {category}
        </BreadcrumbItem>
      </Breadcrumb>
      <Title>
        {title}
      </Title>
    </TemplateHeader>
    <Image
      src={coverImage}
      css={ImageStyle}
    />
  </>
);

export default CurriculumTemplateHeader;
