import React from 'react';
import { graphql } from 'gatsby';

import styled from '@emotion/styled';

import {
  breakpoint,
  mediaQuery,
} from 'styles';

import {
  Markdown,
  SEO,
} from 'components';

import Layout from '../base';
import FAQ from '../faq';

import CurriculumTemplateHeader from './curriculum-template-header';
import CurriculumSummary from './curriculum-summary';
import CurriculumMap from './curriculum-map';

type Props = {
  data: {
    mdx: {
      id: string;
      excerpt: string;
      body: string;
      fields: {
        slug: string,
      },
      frontmatter: {
        title: string,
        category: string,
        calendar: string,
        when: string,
        place: string,
        coverImage: string,
        memberByGithubID: string,
      },
    },
  },
  location: Location;
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
      }
      frontmatter {
        title
        category
        calendar
        when
        place
        coverImage
        memberByGithubID
      }
    }
  }
`

const Container = styled.section`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 6%;

  ${breakpoint(mediaQuery.tablet)} {
    padding: 0 8%;
  }
`;

const CurriculumContentWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  ${breakpoint(mediaQuery.desktop, 'max')} {
    flex-direction: column-reverse;
  }
`;

const CurriculumContent = styled.div`
  flex-grow: 1;
  width: 100%;
  min-height: 300px;

  ${breakpoint(mediaQuery.desktop)} {
    margin-right: 60px;
  }
`;

const CurriculumTemplate = ({
  data: {
    mdx: {
      id,
      excerpt,
      body,
      fields: {
        slug,
      },
      frontmatter: {
        title,
        category,
        calendar,
        when,
        place,
        coverImage,
        memberByGithubID,
      },
    },
  },
}: Props) => {
  const [placeName, address] = place.replace(')', '').split(' (');

  return (
    <Layout>
      <SEO
        title={title}
        description={excerpt}
        image={coverImage}
      />
      <Container>
        <CurriculumTemplateHeader
          category={category}
          title={title}
          coverImage={coverImage}
        />
        <CurriculumContentWrapper>
          <CurriculumContent>
            <Markdown>
              {body}
            </Markdown>
            {address && (
              <CurriculumMap
                placeName={placeName}
                address={address}
              />
            )}
          </CurriculumContent>
          <CurriculumSummary
            slug={slug}
            calendar={calendar}
            when={when}
            placeName={placeName}
            address={address}
          />
        </CurriculumContentWrapper>
        <FAQ />
      </Container>
    </Layout>
  );
};

export default CurriculumTemplate;
